import { useState, useEffect } from 'react';

// https://www.emgoto.com/react-table-of-contents/

const getNestedHeadings = (headingElements) => {
  const nestedHeadings = [];

  headingElements.forEach((heading, index) => {
    const { innerText: title, id } = heading;

    if (heading.nodeName === "H2") {
      const url = `#${title}`
      nestedHeadings.push({ id, title, url, items: [] });
    } else if (heading.nodeName === "H3" && nestedHeadings.length > 0) {
      nestedHeadings[nestedHeadings.length - 1].items.push({
        id,
        title,
      });
    }
  });

  return nestedHeadings;
};

const useHeadings = (selector = "h2") => {
  const [nestedHeadings, setNestedHeadings] = useState([]);

  useEffect(() => {
    const headingElements = Array.from(
      document.querySelectorAll(selector)
      // document.querySelectorAll("h2, h3")
      // document.querySelectorAll("h2, h3, h4")
    );

    const newNestedHeadings = getNestedHeadings(headingElements);
    setNestedHeadings(newNestedHeadings);
  }, []);

  return { items: nestedHeadings };
};

export default useHeadings;

