import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Table } from 'antd';
import "antd/dist/antd.css";

import Layout from "../components/layout"
import Seo from "../components/seo"
import { ListItem } from '../components/common/lists'
import useHeadings from '../lib/useHeadings'
import { parking } from '../lib/tables'
import Information from '../components/common/information'

const AccessPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const tableOfContents = useHeadings();

  return (
    <Layout location={location} title={siteTitle} toc={tableOfContents}>
      <Seo title="アクセス" />
      <h1>アクセス</h1>

      <h2 id="所在地"> 所在地</h2>

        仙台駅東口ヨドバシカメラマルチメディア館の裏側、ファミリーマートの隣りのビルです。

        <Information />

        <iframe 
          title={"当院地図"}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5268.968079452769!2d140.8828014005967!3d38.257531428550045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f8a281bd61bf7db%3A0xfdd98a5e4e9c36ce!2z44CSOTgzLTA4NTIg5a6u5Z-O55yM5LuZ5Y-w5biC5a6u5Z-O6YeO5Yy65qa05bKh77yU5LiB55uu77yW4oiS77yT77yR!5e0!3m2!1sja!2sjp!4v1506335666818" 
          style={{marginTop: "2rem", marginBottom: "2rem", overflow: "hidden", height: "24rem", width: "100%" }}
          frameBorder="0" 
          allowFullScreen
        >
        </iframe>


        <figure className="text-center" 
            style={{border: "solid 2px #329eff"}} >
          <StaticImage
            className=""
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
            src="../images/map.png"
            quality={95}
            alt="アクセスマップ"
          />
          <figcaption>
            <strong> 当院までのアクセスマップ </strong>
          </figcaption>
        </figure>

      <h2 id="電車でお越しの方">電車でお越しの方</h2>


      <ListItem 
        items={
          [
          "JR「仙台駅」で下車、東口から徒歩で6分程度", 
          "地下鉄東西線「宮城野通り駅」で下車、北2番出口から徒歩で1分程度"
          ]}
        >
      </ListItem>

      <h2 id="車でお越しの方"> 車でお越しの方 </h2>


        <p>
        当院専用の駐車場は<strong>ありません</strong>。
        お手数ですが、近隣のパーキングをご利用ください。
        </p>

        <Table dataSource={parking.dataSource} columns={parking.columns} size="small" pagination={false} bordered />

        <iframe 
          title={"近隣パーキング地図"}
          src="https://www.google.com/maps/d/u/1/embed?mid=14Y1ER9v_-jG4s7D8JVRDJYVA4sfvwEcw" 
          style={{marginTop: "2rem", marginBottom: "2rem", overflow: "hidden", height: "24rem", width: "100%" }}
          allowfullscreen
          >
      </iframe>
  </Layout>
  )
}

export default AccessPage

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }`

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allFile(filter: {extension: {nin: ["svg",  "js",  "md","mdx",  "bib",  "pdf"]}}) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`
    // allFile(filter: {extension: {nin: ["svg",  "js",  "md","mdx",  "bib",  "pdf"]}}) {
            // style={{border: "solid 2px #329eff", marginTop: "0rem", marginBottom: "1rem", overflow: "hidden", width: "90%" }}
