import * as React from "react"
import { Descriptions } from 'antd';
import "antd/dist/antd.css";


const Information = () => {
  return (
    <Descriptions
      bordered={false}
      column={1} 
      style={{marginTop: "1rem", marginLeft: "3rem", marginRight: "5rem"}}
    >
      <Descriptions.Item label="住所" 
        contentStyle={{justifyContent: "right"}}
      >
          〒983-0852 宮城県仙台市宮城野区 榴岡4丁目6-31 サンキョービル2階
        </Descriptions.Item>
        <Descriptions.Item label="電話(新患専用)"
          contentStyle={{justifyContent: "right"}}
        >
          050-3355-3691
        </Descriptions.Item>
        <Descriptions.Item label="電話(代表)"
          contentStyle={{justifyContent: "right"}}
        >
          022-369-3691
        </Descriptions.Item>
        <Descriptions.Item label="FAX"
          contentStyle={{justifyContent: "right"}}
        >
          022-369-3050
        </Descriptions.Item>
        <Descriptions.Item label="Email"
      contentStyle={{justifyContent: "right"}}
        >
          sendai.mental.clinic@gmail.com
        </Descriptions.Item>
    </Descriptions>
  )
}

export default Information

